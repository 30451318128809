// 登录过期
const TOKEN_NOT_PROVIDED = 1001;
const TOKEN_INVALID = 1002;
const TOKEN_EXPIRED = 1003;
const TOKEN_COULD_CREATE = 1004;
const CLOSED = 1504;
const INVALID_USER = 100000;
const LOGOUT = 100024;

// 一下需要刷新 token
export const TOKEN_REFRESH = [TOKEN_NOT_PROVIDED, TOKEN_INVALID, TOKEN_EXPIRED, TOKEN_COULD_CREATE];
export const TOKEN_ERROR = [CLOSED, INVALID_USER, LOGOUT];

export const ERROR_ARR = {
    'zh_CN': {
        '429': '操作过于频繁，请稍后再试',
        '200': '网络错误！',
        '401': '权限不足！',
        '402': '权限不足！',
        '403': '权限不足！',
        '404': '数据不存在！',
        '500': '服务器正在升级！',
        '1001': '无法验证',
        '1002': '验证失效',
        '1003': '登录已过期',
        '1004': '无法创建验证',
        '1012': '无权限操作',
        '1011': '输入数据有误',
        '1013': '缺少填写资料',
        '1014': '该对象不存在',
        '1015': '配置错误，配置了不存在的选项',
        '1016': '无记录',
        '1020': '无效请求',
        '1021': '无效参数',
        '1022': '名称不合法',
        '1023': '无效操作',
        '1025': '签名失败',
        '1028': '该地区无法登陆',
        '1031': '操作失败',
        '1032': '添加失败',
        '1033': '修改失败',
        '1034': '删除失败',
        '1035': '功能暂未开放',
        '1501': '系统维护中，请稍后再试',
        '1502': '通道异常，请稍后再试',
        '1503': '功能开发中，请稍后再试',
        '1504': '系统于(GTM +8) 国际时间 02:00 ~ 08:00 点休市，请稍候登陆。',
        '99001':  '邮箱不能为空',
        '99002':  '邮箱格式不正确',
        '99003':  '邮箱长度不能超过50字符',
        '99004':  '邮箱账号已存在',
        '99005':  '密码不能为空',
        '99006':  '密码至少包含6位字符',
        '99007':  '两次密码输入不一致',
        '99008':  '验证码不能为空',
        '1505': '队列维护中，请稍后再试',
        '100000': '您的帐户24小时内没有购买算力包已被冻结，请重新注册购买。',
        '100001': '账户或密码错误',
        '100002': '该用户不存在',
        '100008': '未设置支付密码',
        '100009': '原支付密码不正确',
        '100010': '支付密码不能与登录密码相同',
        '100011': '支付密码不正确',
        '100012': '请选择国家',
        '100015': '需要进行google认证',
        '100016': '验证码不正确',
        '100018': '账户已存在',
        '100019': '无效的邀请码',
        '100022': '请先完成身份认证',
        '100023': '请先完成高级身份认证',
        '100024': '您的账号已在别处登录',
        '100025': '尝试次数过多请稍后再试',
        '100026': '请求次数过多请稍后再试',
        '100027': '密码至少包含6位字符',
        '100028': '支付密码需为6位字符',
        '10100': '该留言已关闭，请创建新的留言',
        "10200": '已存在启用的地址',
        "10201": '地址已存在',
        '110001': '余额不足',
        '110002': '手续费不足',
        '110003': '请先完成上一笔订单',
        '110004': '升级失败，请选择更高档位的算力包',
        '110005': '仅允许上下级划转',
        '110006': '您的帐号无法转出',
        '110007': '仅支持平台内部地址，无法划转至外部地址',
        '110008': '提币或闪兑数额受限',
        '110009': '体验用户无法复投或升级算力包',
        '110010': 'Hash值已存在',
        '110011': '不支持的币种',
        '110012': '请使用外部地址进行闪兑',
        '110013': '系统已停止闪兑交易',
        '120001': '地址生成中，请稍后再试',
    },
    'zh_TW': {
        '429': '操作過於頻繁，請稍後再試',
        '200': '網絡錯誤！ ',
        '401': '權限不足！ ',
        '402': '權限不足！ ',
        '403': '權限不足！ ',
        '404': '數據不存在！ ',
        '500': '服務器正在升級！ ',
        '1001': '無法驗證',
        '1002': '驗證失效',
        '1003': '登錄已過期',
        '1004': '無法創建驗證',
        '1012': '無權限操作',
        '1011': '輸入數據有誤',
        '1013': '缺少填寫資料',
        '1014': '該對像不存在',
        '1015': '配置錯誤，配置了不存在的選項',
        '1016': '無記錄',
        '1020': '無效請求',
        '1021': '無效參數',
        '1022': '名稱不合法',
        '1023': '無效操作',
        '1025': '簽名失敗',
        '1028': '該地區無法登陸',
        '1031': '操作失敗',
        '1032': '添加失敗',
        '1033': '修改失敗',
        '1034': '刪除失敗',
        '1035': '功能暫未開放',
        '1501': '系統維護中，請稍後再試',
        '1502': '通道異常，請稍後再試',
        '1503': '功能開發中，請稍後再試',
        '1504': '系統於(GTM +8) 國際時間 02:00 ~ 08:00 點休市，請稍候登陸。 ',
        '99001':'郵箱不能為空',
        '99002':'郵箱格式不正確',
        '99003':'郵箱長度不能超過50字元',
        '99004':'郵箱帳號已存在',
        '99005':'密碼不能為空',
        '99006':'密碼至少包含6比特字元',
        '99007':'兩次密碼輸入不一致',
        '99008':'驗證碼不能為空',
        '1505': '隊列維護中，請稍後再試',
        '100000': '您的帳戶24小時內沒有購買算力包已被凍結，請重新註冊購買。 ',
        '100001': '賬戶或密碼錯誤',
        '100002': '該用戶不存在',
        '100008': '未設置支付密碼',
        '100009': '原支付密碼不正確',
        '100010': '支付密碼不能與登錄密碼相同',
        '100011': '支付密碼不正確',
        '100012': '請選擇國家',
        '100015': '需要進行google認證',
        '100016': '驗證碼不正確',
        '100018': '賬戶已存在',
        '100019': '無效的邀請碼',
        '100022': '請先完成身份認證',
        '100023': '請先完成高級身份認證',
        '100024': '您的賬號已在別處登錄',
        '100025': '嘗試次數過多請稍後再試',
        '100026': '請求次數過多請稍後再試',
        '100027': '密碼至少包含6位字符',
        '100028': '支付密碼需為6位字符',
        '10100': '該留言已關閉，請創建新的留言',
        "10200": '已存在啟用的地址',
        "10201": '地址已存在',
        '110001': '餘額不足',
        '110002': '手續費不足',
        '110003': '請先完成上一筆訂單',
        '110004': '升級失敗，請選擇更高檔位的算力包',
        '110005': '僅允許上下級劃轉',
        '110006': '您的帳號無法轉出',
        '110007': '僅支持平台內部地址，無法劃轉至外部地址',
        '110008': '提幣或閃兌數額受限',
        '110009': '體驗用戶無法复投或升級算力包',
        '110010': 'Hash值已存在',
        '110011': '不支持的幣種',
        '110012': '請使用外部地址進行閃兌',
        '110013': '系統已停止閃兌交易',
        '120001': '地址生成中，請稍後再試',
    },
    'en': {
        '429': 'The operation is too frequent, please try again later',
        '200': 'Network error! ',
        '401': 'Insufficient authority! ',
        '402': 'Insufficient authority! ',
        '403': 'Insufficient authority! ',
        '404': 'Data does not exist! ',
        '500': 'The server is being upgraded! ',
        '1001': 'Unable to verify',
        '1002': 'Authentication failed',
        '1003': 'Login has expired',
        '1004': 'Unable to create validation',
        '1012': 'No permission to operate',
        '1011': 'Wrong input data',
        '1013': 'Missing information',
        '1014': 'The object does not exist',
        '1015': 'Configuration error, non-existing options configured',
        '1016': 'No record',
        '1020': 'Invalid request',
        '1021': 'Invalid parameter',
        '1022': 'Illegal name',
        '1023': 'Invalid operation',
        '1025': 'Signature failed',
        '1028': 'Cannot log in this area',
        '1031': 'The operation failed',
        '1032': 'Add failed',
        '1033': 'Modification failed',
        '1034': 'Delete failed',
        '1035': 'Feature is not yet available',
        '1501': 'System maintenance, please try again later',
        '1502': 'Channel error, please try again later',
        '1503': 'Feature development, please try again later',
        '1504': 'The system will be closed from 02:00 ~ 08:00 (GTM +8) international time, please log in later. ',
        '99001': 'Email cannot be empty',
        '99002': 'Incorrect email format',
        '99003': 'The email length cannot exceed 50 characters',
        '99004': 'The email account already exists',
        '99005': 'Password cannot be empty',
        '99006': 'Password must contain at least 6 characters',
        '99007': 'The two password entries are inconsistent',
        '99008': 'Verification code cannot be empty',
        '1505': 'Queue maintenance, please try again later',
        '100000': 'Your account has not been purchased for 24 hours. The power pack has been frozen. Please re-register to purchase. ',
        '100001': 'Incorrect account or password',
        '100002': 'This user does not exist',
        '100008': 'No payment password has been set',
        '100009': 'The original payment password is incorrect',
        '100010': 'Payment password cannot be the same as login password',
        '100011': 'Incorrect payment password',
        '100012': 'Please select a country',
        '100015': 'Requires Google authentication',
        '100016': 'Incorrect verification code',
        '100018': 'Account already exists',
        '100019': 'Invalid invitation code',
        '100022': 'Please complete authentication first',
        '100023': 'Please complete advanced authentication first',
        '100024': 'Your account is already logged in elsewhere',
        '100025': 'Too many attempts, please try again later,',
        '100026': 'Too many requests, please try again later,',
        '100027': 'Password contains at least 6 characters',
        '100028': 'Payment password must be 6 characters',
        '10100': 'This message is closed. Please create a new message',
        "10200": 'An enabled address already exists',
        "10201": 'Address already exists',
        '110001': 'Insufficient balance',
        '110002': 'Insufficient handling fee',
        '110003': 'Please complete the previous order first',
        '110004': 'Upgrade failed, please select a higher-level hashrate package',
        '110005': 'Only allow subordinates to transfer',
        '110006': 'Your account cannot be transferred',
        '110007': 'Only supports the internal address of the platform and cannot be transferred to external addresses',
        '110008': 'Limited withdrawal or flash amount is restricted',
        '110009': 'Experienced users cannot reinvest or upgrade the hashrate package',
        '110010': 'Hash value already exists',
        '110011': 'Unsupported currencies',
        '110012': 'Please use external address for flashing',
        '110013': 'The system has stopped flash transactions',
        '120001': 'Address generation, please try again later',
    },
    'ja': {
        '429': '操作が多すぎます。しばらくしてからもう一度お試しください',
        '200': 'ネットワークエラー！ ',
        '401': '権限が不十分です！ ',
        '402': '権限が不十分です！ ',
        '403': '権限が不十分です！ ',
        '404': 'データが存在しません！ ',
        '500': 'サーバーをアップグレードしています！ ',
        '1001': '確認できません',
        '1002': '認証に失敗しました',
        '1003': 'ログインの有効期限が切れています',
        '1004': '検証を作成できません',
        '1012': '操作する権限がありません',
        '1011': '間違った入力データ',
        '1013': '情報がありません',
        '1014': 'オブジェクトが存在しません',
        '1015': '設定エラー,存在しないオプションが設定されました',
        '1016': '記録なし',
        '1020': '無効なリクエスト',
        '1021': '無効なパラメーター',
        '1022': '違法な名前',
        '1023': '無効な操作',
        '1025': '署名に失敗しました',
        '1028': 'このエリアにログインできません',
        '1031': '操作に失敗しました',
        '1032': '追加に失敗しました',
        '1033': '変更に失敗しました',
        '1034': '削除失敗',
        '1035': '機能はまだ利用できません',
        '1501': 'システムメンテナンス,しばらくしてからもう一度お試しください',
        '1502': 'チャンネルエラー,しばらくしてからもう一度お試しください',
        '1503': '機能開発,後で再試行してください',
        '1504': 'システムは02:00〜08:00（GTM +8）の国際時間で閉鎖されます。後でログインしてください。 ',
        '9901':'メールボックスを空にすることはできません',
        '9902': 'メールボックスの形式が正しくありません',
        '9903':'メールボックスの長さは50文字を超えてはいけません',
        '9904':'メールアカウントは既に存在します',
        '9905':'パスワードを空にすることはできません',
        '9906':'パスワードには少なくとも6文字が含まれています',
        '9907':'2回のパスワード入力が一致しない',
        '9908': '認証コードを空にすることはできません',
        '1505': 'キューのメンテナンス,後で再試行してください',
        '100000': 'アカウントは24時間購入されていません。パワーパックは凍結されています。購入するには再登録してください。 ',
        '100001': 'アカウントまたはパスワードが正しくありません',
        '100002': 'このユーザーは存在しません',
        '100008': '支払いパスワードが設定されていません',
        '100009': '元の支払いパスワードが間違っています',
        '100010': '支払いパスワードをログインパスワードと同じにすることはできません',
        '100011': '支払いパスワードが間違っています',
        '100012': '国を選択してください',
        '100015': 'Google認証が必要です',
        '100016': '確認コードが正しくありません',
        '100018': 'アカウントは既に存在します',
        '100019': '無効な招待コード',
        '100022': '最初に認証を完了してください',
        '100023': '先に高度な認証を完了してください',
        '100024': 'あなたのアカウントはすでに他の場所でログインしています',
        '100025': '試行回数が多すぎます。しばらくしてからもう一度お試しください',
        '100026': 'リクエストが多すぎます。しばらくしてからもう一度お試しください',
        '100027': 'パスワードには6文字以上が含まれています',
        '100028': '支払いパスワードは6文字である必要があります',
        '10100': 'このメッセージは閉じられています。新しいメッセージを作成してください',
        "10200": '有効なアドレスはすでに存在しています',
        "10201": 'アドレスはすでに存在しています',
        '110001': '残高不足',
        '110002': '手数料が不十分です',
        '110003': '最初に前の注文を完了してください',
        '110004': 'アップグレードに失敗しました。より高いレベルのハッシュパッケージを選択してください',
        '110005': '部下だけが転勤できるようにする',
        '110006': 'アカウントを譲渡できません',
        '110007': 'プラットフォームの内部アドレスのみをサポートし,外部アドレスに転送できません',
        '110008': '引き出しの制限またはフラッシュの量が制限されています',
        '110009': '経験豊富なユーザーはハッシュレートパッケージを再投資またはアップグレードできません',
        '110010': 'ハッシュ値は既に存在します',
        '110011': 'サポートされていない通貨',
        '110012': '点滅には外部アドレスを使用してください',
        '110013': 'システムがフラッシュトランザクションを停止しました',
        '120001': 'アドレス生成,後で再試行してください',
    },
    'vi': {
        '429': 'Hoạt động quá thường xuyên, vui lòng thử lại sau',
        '200': 'Lỗi mạng! ',
        '401': 'Không đủ thẩm quyền! ',
        '402': 'Không đủ thẩm quyền! ',
        '403': 'Không đủ thẩm quyền! ',
        '404': 'Dữ liệu không tồn tại! ',
        '500': 'Máy chủ đang được nâng cấp! ',
        '1001': 'Không thể xác minh',
        '1002': 'Xác thực thất bại',
        '1003': 'Đăng nhập đã hết hạn',
        '1004': 'Không thể tạo xác thực',
        '1012': 'Không được phép hoạt động',
        '1011': 'Dữ liệu đầu vào sai',
        '1013': 'Thiếu thông tin',
        '1014': 'Đối tượng không tồn tại',
        '1015': 'Lỗi cấu hình, các tùy chọn không tồn tại được định cấu hình',
        '1016': 'Không có hồ sơ',
        '1020': 'Yêu cầu không hợp lệ',
        '1021': 'Tham số không hợp lệ',
        '1022': 'Tên bất hợp pháp',
        '1023': 'Hoạt động không hợp lệ',
        '1025': 'Chữ ký thất bại',
        '1028': 'Không thể đăng nhập trong khu vực này',
        '1031': 'Thao tác thất bại',
        '1032': 'Thêm thất bại',
        '1033': 'Sửa đổi thất bại',
        '1034': 'Xóa không thành công',
        '1035': 'Tính năng chưa khả dụng',
        '1501': 'Bảo trì hệ thống, vui lòng thử lại sau',
        '1502': 'Lỗi kênh, vui lòng thử lại sau',
        '1503': 'Phát triển tính năng, vui lòng thử lại sau',
        '1504': 'Hệ thống sẽ đóng cửa từ 02:00 ~ 08:00 (GTM +8) theo giờ quốc tế, vui lòng đăng nhập sau. ',
        '99001':  'Hộp thư không được để trống',
        '99002':  'Định dạng hộp thư không chính xác',
        '99003':  'Chiều dài hộp thư không được vượt quá 50 ký tự',
        '99004':  'Tài khoản email đã tồn tại',
        '99005':  'Mật khẩu không được để trống',
        '99006':  'Mật khẩu chứa ít nhất 6 ký tự',
        '99007':  'Hai lần nhập mật khẩu không phù hợp',
        '99008':  'Captcha không được để trống',
        '1505': 'Bảo trì xếp hàng, vui lòng thử lại sau',
        '100000': 'Tài khoản của bạn đã không được mua trong 24 giờ. Gói điện đã bị đóng băng. Vui lòng đăng ký lại để mua. ',
        '100001': 'Tài khoản hoặc mật khẩu không chính xác',
        '100002': 'Người dùng này không tồn tại',
        '100008': 'Không có mật khẩu thanh toán nào được đặt',
        '100009': 'Mật khẩu thanh toán ban đầu không chính xác',
        '100010': 'Mật khẩu thanh toán không thể giống như mật khẩu đăng nhập',
        '100011': 'Mật khẩu thanh toán không chính xác',
        '100012': 'Vui lòng chọn một quốc gia',
        '100015': 'Yêu cầu xác thực Google',
        '100016': 'Mã xác minh không chính xác',
        '100018': 'Tài khoản đã tồn tại',
        '100019': 'Mã thư mời không hợp lệ',
        '100022': 'Vui lòng hoàn tất xác thực trước',
        '100023': 'Vui lòng hoàn thành xác thực nâng cao trước',
        '100024': 'Tài khoản của bạn đã được đăng nhập ở nơi khác',
        '100025': 'Quá nhiều lần thử, vui lòng thử lại sau',
        '100026': 'Quá nhiều yêu cầu, vui lòng thử lại sau',
        '100027': 'Mật khẩu chứa ít nhất 6 ký tự',
        '100028': 'Mật khẩu thanh toán phải có 6 ký tự',
        '10100': 'Tin nhắn này đã bị đóng. Vui lòng tạo một tin nhắn mới',
        "10200": 'Đã tồn tại một địa chỉ được kích hoạt',
        "10201": 'Địa chỉ đã tồn tại',
        '110001': 'Số dư không đủ',
        '110002': 'Phí xử lý không đủ',
        '110003': 'Vui lòng hoàn thành đơn hàng trước',
        '110004': 'Nâng cấp thất bại, vui lòng chọn gói hashrate cấp cao hơn',
        '110005': 'Chỉ cho phép cấp dưới chuyển nhượng',
        '110006': 'Tài khoản của bạn không thể được chuyển',
        '110007': 'Chỉ hỗ trợ địa chỉ nội bộ của nền tảng và không thể được chuyển sang địa chỉ bên ngoài',
        '110008': 'Hạn chế rút tiền hoặc số tiền flash bị hạn chế',
        '110009': 'Người dùng có kinh nghiệm không thể tái đầu tư hoặc nâng cấp gói hashrate',
        '110010': 'Giá trị băm đã tồn tại',
        '110011': 'Tiền tệ không được hỗ trợ',
        '110012': 'Vui lòng sử dụng địa chỉ bên ngoài để nhấp nháy',
        '110013': 'Hệ thống đã ngừng giao dịch flash',
        '120001': 'Tạo địa chỉ, vui lòng thử lại sau',
    },
    'ko': {
        '429': '작업이 너무 자주 발생합니다. 나중에 다시 시도하십시오',
        '200': '네트워크 오류! ',
        '401': '권한이 충분하지 않습니다! ',
        '402': '권한이 부족합니다! ',
        '403': '권한이 부족합니다! ',
        '404': '데이터가 없습니다! ',
        '500': '서버가 업그레이드되고 있습니다! ',
        '1001': '확인할 수 없습니다',
        '1002': '인증 실패',
        '1003': '로그인이 만료되었습니다',
        '1004': '검증을 만들 수 없습니다',
        '1012': '작동 권한이 없습니다',
        '1011': '잘못된 입력 데이터',
        '1013': '없는 정보',
        '1014': '개체가 존재하지 않습니다',
        '1015': '구성 오류, 존재하지 않는 옵션 구성',
        '1016': '기록 없음',
        '1020': '잘못된 요청',
        '1021': '잘못된 매개 변수',
        '1022': '잘못된 이름',
        '1023': '잘못된 작업',
        '1025': '서명 실패',
        '1028': '이 지역에 로그인 할 수 없습니다',
        '1031': '작업이 실패했습니다',
        '1032': '추가 실패',
        '1033': '수정 실패',
        '1034': '삭제 실패',
        '1035': '기능을 아직 사용할 수 없습니다',
        '1501': '시스템 유지 보수, 나중에 다시 시도하십시오',
        '1502': '채널 오류입니다. 나중에 다시 시도하십시오',
        '1503': '기능 개발, 나중에 다시 시도하십시오',
        '1504': '시스템은 국제 시간으로 02:00 ~ 08:00 (GTM +8)에 닫힙니다. 나중에 로그인하십시오. ',
        '99001':'사서함은 비워둘 수 없습니다',
        '99002':'사서함 형식이 올바르지 않습니다',
        '99003':'사서함 길이는 50자를 초과할 수 없습니다',
        '99004':'사서함 계정이 이미 존재합니다',
        '99005':'비밀번호는 비워둘 수 없습니다',
        '99006':'비밀번호는 6자 이상',
        '99007':'두 번의 암호 입력 불일치',
        '99008':'확인 코드는 비워둘 수 없습니다',
        '1505': '큐 유지 관리, 나중에 다시 시도하십시오',
        '100000': '계정이 24 시간 동안 구매되지 않았습니다. 전원 팩이 정지되었습니다. 다시 등록하여 구입하십시오. ',
        '100001': '잘못된 계정 또는 비밀번호',
        '100002': '이 사용자는 존재하지 않습니다',
        '100008': '결제 비밀번호가 설정되지 않았습니다',
        '100009': '원래 결제 비밀번호가 잘못되었습니다.',
        '100010': '결제 비밀번호는 로그인 비밀번호와 같을 수 없습니다',
        '100011': '잘못된 결제 비밀번호',
        '100012': '국가를 선택하십시오',
        '100015': 'Google 인증 필요',
        '100016': '잘못된 인증 코드',
        '100018': '계정이 이미 있습니다',
        '100019': '잘못된 초대 코드',
        '100022': '먼저 인증을 완료하십시오',
        '100023': '먼저 고급 인증을 완료하십시오',
        '100024': '계정이 이미 다른 곳에 로그인되어 있습니다',
        '100025': '너무 많은 시도입니다. 나중에 다시 시도하십시오.',
        '100026': '요청이 너무 많습니다. 나중에 다시 시도하십시오.',
        '100027': '비밀번호에 6 자 이상이 포함되어 있습니다',
        '100028': '결제 비밀번호는 6 자 여야합니다',
        '10100': '이 메시지는 닫힙니다. 새 메시지를 작성하십시오',
        "10200": '사용 가능한 주소가 이미 있습니다',
        "10201": '주소가 이미 있습니다',
        '110001': '불충분 한 잔액',
        '110002': '처리 수수료가 충분하지 않습니다',
        '110003': '먼저 이전 주문을 완료하십시오',
        '110004': '업그레이드에 실패했습니다. 더 높은 수준의 해시 레이트 패키지를 선택하십시오',
        '110005': '하위 직원 만 이체 허용',
        '110006': '계정을 이전 할 수 없습니다',
        '110007': '플랫폼의 내부 주소 만 지원하며 외부 주소로 전송할 수 없습니다',
        '110008': '제한된 인출 또는 플래시 금액이 제한됩니다',
        '110009': '경험이있는 사용자는 해시 레이트 패키지를 재투자하거나 업그레이드 할 수 없습니다',
        '110010': '해시 값이 이미 존재합니다',
        '110011': '지원되지 않는 통화',
        '110012': '깜박임에 외부 주소를 사용하십시오',
        '110013': '시스템이 플래시 트랜잭션을 중지했습니다',
        '120001': '주소 생성, 나중에 다시 시도하십시오',
    }
};
