import axios from 'axios';
import Cookies from 'js-cookie';
import {BASE_URL, KEY, IV} from '../config/env';
import {TOKEN_REFRESH, ERROR_ARR, TOKEN_ERROR} from '../config/error';
import store from '../store/index';
import router from '../router/index';
import {Spin, Message} from "view-design";
import CryptoJS from 'crypto-js';


class ApiRequest {
    /**
     * aes加密
     * @param data
     * @returns {string}
     */
    static encrypt(data){
        let key = CryptoJS.enc.Utf8.parse(CryptoJS.SHA256(KEY).toString().substr(0, 32)); // 秘钥
        let iv = CryptoJS.enc.Utf8.parse(CryptoJS.SHA256(IV).toString().substr(0, 16)); //向量iv
        let encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CFB,
            padding: CryptoJS.pad.ZeroPadding
        });

        return encrypted.toString();
    }

    /**
     * 发送 Http Request
     *
     * @param {string} requestType
     * @param {string} url
     * @param {Object} data
     * @returns {Promise<any>}
     */
    static send(requestType, url, data, base_url = BASE_URL) {
        // 设置 Token
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.user.token}`;

        let dataNew = JSON.parse(JSON.stringify(data));

        Spin.show();

        return new Promise((resolve, reject) => {
            axios[requestType](base_url + url, dataNew)
                .then(response => {
                    Spin.hide();

                    // 若登录异常，则终止
                    if (TOKEN_REFRESH.indexOf(response.data.code) > -1) {
                        this.onTokenInvalid(response.data);
                        return;
                    }

                    // 成功
                    this.onSuccess(response.data);

                    if (TOKEN_ERROR.indexOf(response.data.code) > -1) {
                        this.onTokenInvalid(response.data);
                        return;
                    }
                    // 返回 Promise
                    resolve(response.data);
                })
                .catch(error => {
                    Spin.hide();
                    this.onFail(error.response);
                    reject(error.response);
                });
        });
    }

    /**
     * 登录过期，清空本地token，跳转至登录页面
     *
     * @param {object} data
     */
    static onTokenInvalid(data) {
        Message.error({content: ERROR_ARR[localStorage.getItem('locale') || 'en'][data.code] || data.msg});
        store.state.user.token = '';
        store.state.user.user = '';
        Cookies.remove('user');
        Cookies.remove('token');
        if(router.currentRoute.name !== 'login') router.push('/login');
    }

    /**
     * Handle a successful request.
     *
     * @param {object} data
     */
    static onSuccess() {

    }

    /**
     * Handle a failed request.
     *
     * @param {object} errors
     */
    static onFail(errors) {
        console.log(errors);
        if(errors.status == 200) {
            Message.error({content: ERROR_ARR[localStorage.getItem('locale') || 'en'][errors.status] || errors.statusText});
        }
        if(errors.status == 422) {
            console.log(422)
            let arr = [99001, 99002, 99003, 99004, 99005, 99006, 99007, 99008]
            for (let i = 0; i < arr.length; i++) {
                Object.keys(errors.data.errors).forEach(function (key) {
                    console.log(ERROR_ARR['zh_CN'][arr[i]])
                    console.log(errors.data.errors[key].join(""))
                    if (errors.data.errors[key].join("").indexOf(ERROR_ARR['zh_CN'][arr[i]]) > -1) {
                        Message.error({
                            content: ERROR_ARR[localStorage.getItem('locale')][arr[i]],
                            duration: 8
                        });
                    }
                });
                continue
            }
        }
        if(errors.status == 500) {
            Message.error({content: ERROR_ARR[localStorage.getItem('locale') || 'en'][errors.status] || errors.statusText});
        }
    }

    /**
     * Send a GET request to the given URL.
     * .
     * @param {string} url
     * @param {Object} data
     */
    static get(url, data = {}) {
        return this.send('get', url, data);
    }

    /**
     * Send a POST request to the given URL.
     * .
     * @param {string} url
     * @param {Object} data
     */
    static post(url, data = {}, baseUrl) {
        return this.send('post', url, data, baseUrl);
    }

    /**
     * Send a PUT request to the given URL.
     * .
     * @param {string} url
     * @param {Object} data
     */
    static put(url, data = {}) {
        return this.send('put', url, data);
    }

    /**
     * Send a PATCH request to the given URL.
     * .
     * @param {string} url
     * @param {Object} data
     */
    static patch(url, data = {}) {
        return this.send('patch', url, data);
    }

    /**
     * Send a DELETE request to the given URL.
     * .
     * @param {string} url
     * @param {Object} data
     */
    static delete(url, data = {}) {
        return this.send('delete', url, data);
    }
}

export default ApiRequest;
